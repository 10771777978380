.react-datepicker {
  /* min-width: 315px; */
}

.boder {
  border: 1px solid black;
  width: 100%;
}

.inputfield {
  margin-top: 15px;
  margin-bottom: 10px
}

.inputfield .col {
  z-index: 1;
}

.inputfield .col input {
  z-index: 2;
  left: 25%;
}
.is-date-picker .react-datepicker-popper {
  z-index: 2;
}
.is-date-picker .react-datepicker-popper .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 5px 9px;
}
button.example-custom-input {
  background: none;
  /* border: 1px solid; */
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
}

.table td {
  text-align: center;
}

.center {
  text-align: center
}

.input.checkbox {
  zoom: 1.8;
}

.product-table {
  margin-top: 35px
}

.product-table .table-responsive table {
  
}

/* css perserver Anh */

.sidebar .nav-dropdown-items .nav-item {
  margin-left: 15px;
}

@media (min-width: 992px) {
  .sidebar-fixed .sidebar {
    width: 210px !important;
  }
}

.sidebar .nav-link {
  padding: 0.75rem 0.5rem !important;
}

.sidebar .sidebar-nav {
  width: 210px !important;
}

.sidebar .nav {
  width: 210px !important;
}

.sidebar {
  background: #173c5f !important;
}

.sidebar .nav-link {
  color: #fff074 !important;
}

.sidebar .nav-title {
  padding: 0.75rem 1rem;
  font-size: 94% !important;
  font-weight: 700;
  color: #63c2de !important;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  /*Button disabled - CSS color class*/
  color: #c0c0c0 !important;
  background-color: #ffffff !important;
}

.page-link:hover {
  z-index: 2;
  color: #fff !important;
  background-color: #20a8d8 !important;
  border-color: #20a8d8;
}

.hide {
  display: none !important;
}

.customerreviews {
  padding-top: 50px;
  display: table;
  margin: 0 auto;
  width: 50%;
  border: 1px solid cadetblue;
  padding: 20px;
  margin-top: 50px;
  border-radius: 10px;
  background: aliceblue;
  -webkit-box-shadow: 0px 0px 30px 3px rgba(22, 61, 18, 0.72);
  -moz-box-shadow: 0px 0px 30px 3px rgba(22, 61, 18, 0.72);
  box-shadow: 0px 0px 30px 3px rgba(22, 61, 18, 0.72);
}

.right {
  float: right;
}

.dv-star-rating {
  font-size: 40px;
}

.bold {
  font-weight: 700;
  color: chocolate;
}

.bold-x {
  font-weight: 700;
  color: chocolate;
  animation: blinker 1s linear infinite;
}


@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.center {
  display: table;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-bottom: 30px !important;
}

img.img-avatar-user:hover {
  max-width: 100%;
  height: auto;
  border-radius: 50em;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.4);
  margin: 12px 0;
  cursor: pointer;
}

.rating {
  margin: 15px 0;
}

/* img.img-avatar-user {
  max-width: 100%;
  height: auto;
  border-radius: 50em;

} */

@media only screen and (max-width: 600px) {
  .customerreviews {
    width: 100%;
    margin-top: 0;
    border-radius: 0;
    border: none;
    height: 100%;
    min-height: 100%;
  }
}

#profile-page h1 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

#profile-page p {
  color: #1a1a1a;
  margin: 0 0 10px;
}

.required {
  color: red;
}

p {
  color: #1a1a1a;
  margin: 0 0 10px;
}

label.label-bold {
  margin-top: 5px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
  font-family: "Helvetica Neue", arial, Georgia, Serif;
  line-height: 1.4;
  color: #1a1a1a;
}

.profile-pic-background {
  display: table;
  margin: 10px auto;
  -ms-filter: "alpha(opacity=30)";
  filter: alpha(opacity=30);
}

.app-header .nav-item .nav-link {
  color: lightslategray;
}

.marquee {
  width: 30%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: lightslategray;
  font-size: larger;
  margin-left: 40px;
}

.app-header .nav-item .nav-link:hover {
  font-size: larger;
  color: brown;
  transform-style: preserve-3d;
  transition-delay: 5ms;
  -webkit-transition: background-color 3s ease-out;
  -moz-transition: background-color 3s ease-out;
  -o-transition: background-color 3s ease-out;
  transition: background-color 3s ease-out;
}

.red {
  background-color: red !important;
}

.xxx {
  position: absolute;
  top: 10;
  display: inline-block;
  background-color: #fd0303;
  height: 79px;
  margin-top: 6px;
  width: 37px;
  font-size: 11px;
  padding: 7px;
  color: white;
  margin-right: 5px;
  margin-left: 50px;
  animation: roll 3s infinite;
  transform: rotate(46deg);
  opacity: .7;
}

.burlywood {
  background: burlywood;
}

ul.react-datepicker__time-list {
  padding: 0;
}

.order-print-container {
  width: 50%;
  min-width: 400px;
  display: block;
  margin: auto;
  padding: 5px;
  color: black;
  background: white
}

#print-area {
  display: none;
}

.react-datepicker__input-container input {
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
}

.bg-Table {
  background: wheat;
}
.total-bill-styles-title {
  font-size: 20px;
}

.total-bill-styles {
  font-size: 20px;
  margin: auto;
  width: 29%;
}
.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;
  background-color: white;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 0px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td{
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
      width: 100%;
      display: block;
      text-align: center;
  }
  
  .invoice-box table tr.information table td {
      width: 100%;
      display: block;
      text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}
@media print {
  body>div {
    display: none;
  }

  #print-area {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }

  /* .section-to-print,
    .section-to-print * {
        visibility: visible;
    }
    .section-to-print {
        position: absolute;
        left: 0;
        top: 0;
    } */
}

 /* Rank Customer css */
 .c-rank{
   text-align: center;
 }
 .c-rank .fa{
  border: 1px solid #000;
  padding: 5px;
  font-size: 45px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  color: rgba(0,0,0,0.2);
 }
 .c-rank .fa.is-gold{
   color: #ffff00;
 }  
 .c-rank .fa.is-silver{
  color: #a6a6a6;
 }
 .c-rank .fa.is-bronze{
  color: #ff4000;
 }
 .c-rank span{
   display: block;
   font-weight: 700;
 }
 /* END Rank Customer css */